import { Button, Card, OfferInformationCard } from '~src/components/display';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { DefaultLayout } from '~src/components/layouts';
import { OfferProductType } from '~src/gql';
import { useLocalization } from '~src/hooks';
import { useSyncContactInformationWithCRM, useSyncOfferWithCrm, useUpdateOfferProductPrice } from '~src/hooks/services';
import {
  Customer,
  mapCustomerToCustomerInput,
  mapResidenceToResidenceInput,
  Offer,
  OfferProducts,
  Residence,
} from '~src/types';
import { findOfferValidProductType, offerHasDisabledProducts } from '~src/utilities/calculationHelper';

import { CustomerFinancing, CustomerHeatPumpSubsidy, CustomerNotes, CustomerPriceInflation } from '../shared/customer';
import { OfferStatistics } from '../shared/offer';
import { OfferProductTable } from '../shared/offer/OfferProductTable';
import { HeatPumpSiteVisitSummary } from './HeatPumpSiteVisitSummary';
import { OfferActions } from './OfferActions';
import { OfferInstallerLinks } from './OfferInstallerLinks';
import { OfferNotes } from './OfferNotes';
import { OfferPageExpenseTables } from './OfferPageExpenseTables';
import { OfferPageHeader } from './OfferPageHeader';
import { OfferReservations } from './OfferReservations';
import { SentOffersHistory } from './SentOffersHistory';
import { SolarSiteVisitSummary } from './SolarSiteVisitSummary';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import {
  disabledOfferPageContent,
  disabledProductsInner,
  noteContainer,
  offerPageContent,
  syncButton,
} from './offerPageContent.module.css';

type OfferPageContentProps = {
  offer?: Omit<Offer, 'customer' | 'residence'> & OfferProducts;
  residence?: Residence;
  customer?: Customer;
};

export const OfferPageContent = ({ offer, residence, customer }: OfferPageContentProps) => {
  const translate = useLocalization();

  const { syncContactInformationWithCRM, isLoading: isSyncingContact } = useSyncContactInformationWithCRM();
  const { syncOfferWithCrm, isLoading: isSyncingOffer } = useSyncOfferWithCrm();

  const hasDisabledProducts = offerHasDisabledProducts(offer ?? {});

  const residenceInput = mapResidenceToResidenceInput(residence);
  const customerInput = mapCustomerToCustomerInput(customer);

  const { updateOfferProductPrice, isLoading: isUpdatingProductPrices } = useUpdateOfferProductPrice();

  const updateProductPrice = async (unitId: number, price: number, type: OfferProductType) => {
    if (!offer?.id) {
      return;
    }

    await updateOfferProductPrice({ offerId: offer.id, unitId, price, type });
  };

  const offerType = findOfferValidProductType(offer);

  if (!offerType || !offer?.id) {
    return null;
  }

  if (hasDisabledProducts) {
    return (
      <DefaultLayout>
        <OfferPageHeader offer={offer} residence={residenceInput} />

        <div className={disabledOfferPageContent}>
          <Card>
            <div className={disabledProductsInner}>{translate.OFFER_HAS_DISABLED_PRODUCTS}</div>
          </Card>
        </div>

        <div className={displayColumn}>
          <OfferProductTable offer={offer} updatePrice={() => {}} title={translate.PRODUCTS} />
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <OfferPageHeader offer={offer} residence={residenceInput} />

      <div className={offerPageContent}>
        <div className={displayColumn}>
          <Card>
            <Button
              type='primary'
              className={syncButton}
              loading={isSyncingContact}
              onClick={() => syncContactInformationWithCRM(residence?.id ?? residence?.id)}
            >
              {translate.SYNCHRONIZE_CONTACT_WITH_CRM}
            </Button>

            <Button
              type='primary'
              className={syncButton}
              loading={isSyncingOffer}
              onClick={() => syncOfferWithCrm(Number(offer.id))}
            >
              {translate.SYNCHRONIZE_OFFER_WITH_CRM}
            </Button>
          </Card>

          <CustomerFinancing customer={customerInput} />

          <CustomerHeatPumpSubsidy customer={customerInput} />

          <CustomerPriceInflation customer={customerInput} />

          <OfferActions offer={offer} />

          <SentOffersHistory offer={offer} />
        </div>

        <div className={displayColumn}>
          <div className={displayRow}>
            <div className={displayColumn}>
              <OfferInformationCard offer={offer} address={residence?.address} />

              <OfferInstallerLinks offer={offer} />
            </div>

            <div className={displayColumn}>
              <OfferStatistics customer={customerInput} layout='box' offerProducts={offer} residence={residenceInput} />

              <OfferProfitMargins offer={offer} />
            </div>
          </div>

          <OfferProductTable
            offer={offer}
            updatePrice={updateProductPrice}
            title={translate.PRODUCTS}
            loading={isUpdatingProductPrices}
          />

          <OfferPageExpenseTables offer={offer} />

          <div className={noteContainer}>
            <OfferReservations offer={offer} />

            <OfferNotes offer={offer} />

            <CustomerNotes customer={customerInput} rows={10} title={translate.CUSTOMER_NOTES} />
          </div>

          {offerType === 'solar' ? (
            <SolarSiteVisitSummary residence={residence} residenceID={residence?.id} />
          ) : (
            <HeatPumpSiteVisitSummary residenceID={residence?.id} />
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
