import { Dropdown } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useMarkOfferAsSent } from '~src/hooks/services';
import { Offer, OfferPdfTypeEnum } from '~src/types';

import { offerPdfPreviewWrapper } from './offerPdfPreviewLink.module.css';

type OfferPdfPreviewLinkProps = {
  offer?: {
    id?: number;
    finalPreview?: Offer['finalPreview'];
    preliminaryPreview?: Offer['preliminaryPreview'];
  };
  type: OfferPdfTypeEnum;
  isLoading?: boolean;
  onRegenerate?: (type: OfferPdfTypeEnum) => void;
};

export const OfferPdfPreviewLink = ({ offer, type, isLoading, onRegenerate }: OfferPdfPreviewLinkProps) => {
  const translate = useLocalization();

  const { markOfferAsSent } = useMarkOfferAsSent();

  const { id, finalPreview, preliminaryPreview } = offer ?? {};

  if (!id) {
    return null;
  }

  const isFinal = type === 'final';

  const previewLink = isFinal ? finalPreview : preliminaryPreview;

  const offerTranslation = (isFinal ? translate.FINAL_OFFER : translate.PRELIMINARY_OFFER).toLowerCase();

  return (
    <div className={offerPdfPreviewWrapper}>
      <Dropdown.Button
        loading={isLoading}
        disabled={isLoading}
        onClick={() => (!previewLink ? onRegenerate?.(type) : globalThis.open(previewLink, '_blank'))}
        type='primary'
        menu={{
          items: [
            {
              key: `mark-${id}-as-sent`,
              label: translate.MARK_OFFER_AS_SENT,
              disabled: isLoading,
              onClick: () => markOfferAsSent(id, type),
            },
            {
              key: `regenerate-${id}`,
              label: `${translate.RERENDER_PDF} ${offerTranslation}`,
              disabled: isLoading,
              onClick: () => onRegenerate?.(type),
            },
          ],
        }}
      >
        {translate.VIEW_PDF} {offerTranslation}
      </Dropdown.Button>
    </div>
  );
};
