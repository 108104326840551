import round from 'lodash/round';

import { calculateRoofInclination as unroundedCalculateRoofInclination, Roof } from '@bodilenergy/domain';

export {
  aggregateHeatingOutput,
  areOfferProductsValid,
  calculateAnnualConsumptionForElectricCar,
  calculateAverageIntensity,
  calculateElectricitySellPrice,
  calculateHeatDistributionValue,
  calculateHeatLoadPerArea,
  calculateHeatPumpScores,
  calculateInclinedDepth,
  calculateMaximumSolarPanels,
  calculateNominalHeatingRequirements,
  calculateOfferBuyPrice,
  calculateOfferMargins,
  calculateOfferPrice,
  calculatePaybackTime,
  calculateResidenceHeatingRequired,
  calculateRoofHeight,
  findConstantForHeating,
  findLoanCharacteristics,
  findOfferMainProductType,
  findOfferValidProductType,
  mapConstantsToEnergyOptions,
  offerHasDisabledProducts,
} from '@bodilenergy/domain';
export { filterFalsy, formatCurrency, withoutVAT, withVAT } from '@bodilenergy/util';

export const calculateRoofInclination = (parameters: Pick<Roof, 'planeDepth' | 'height'>) =>
  round(unroundedCalculateRoofInclination(parameters), 2);
