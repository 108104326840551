import { Card, Switch, Text } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useUpsertCustomer } from '~src/hooks/services';
import { CustomerInput } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';

type CustomerFinancingProps = {
  customer?: CustomerInput;
  updateCustomer?: (arg: CustomerInput) => void;
};

export const CustomerHeatPumpSubsidy = ({ customer, updateCustomer }: CustomerFinancingProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const { upsertCustomer } = useUpsertCustomer();

  const setUseSubsidy = async (newValue: boolean) => {
    const updated: CustomerInput = {
      ...customer,
      includeHeatPumpSubsidy: newValue,
    };

    await upsertCustomer(updated);

    updateCustomer?.(deepNullToUndefined(updated));

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const includeHeatPumpSubsidy = customer?.includeHeatPumpSubsidy ?? false;

  return (
    <Card title={translate.SUBSIDY}>
      <Text>{translate.CUSTOMER_HEAT_PUMP_SUBSIDY_PROMPT}</Text>

      <Switch defaultChecked={includeHeatPumpSubsidy} onChange={newValue => setUseSubsidy(newValue)} />
    </Card>
  );
};
